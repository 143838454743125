/* eslint react/prop-types: 0 */
import React from 'react';
import { graphql } from 'gatsby';
import {dataChristian} from '../hooks/dataPersons';
import Person from '../components/person';

function Christian({ data }) {
	const personImage = data.file.childImageSharp.fluid;
	const cv = [
		[ '2015-2020', 'Manuelle Therapie nach dem Maitland-Konzept' ],
		[ '2015', `Sportphysiotherapeutische Behandlung der Schulter (ESP\u2011Wien)` ],
		[ '2011-2014', 'FH Campus Wien – Bachelorstudium Physiotherapie' ],
		[ '2006-2011', 'Studium der Sportwissenschaften' ],
		[
			'weitere Ausbildungen',
			'Snowboard- und Skilehrer, Rettungsschwimmer, Rettungssanitäter und Gesund und Fit-Trainer'
		]
	];

	const cv2 = [
		[ 'seit 2019', 'Betreuung des Football Teams der Danube Dragons Youth' ],
		[ 'seit 2016', 'bei Sportmedic' ],
		[ 'seit 2015', 'Freiberufliche Tätigkeit' ],
		[ '2015-2017', 'BVAEB - Ambulatorium Wien Praterstern' ],
		[ '2007-2014', 'ASKÖ Wien Trainer für Kinder- und Seniorenkurse' ]
	];

	const content = [
		[
			'',
			`Manche Wege sind vorgezeichnet. Schon als Kind war mein Bewegungsdrang mehr als ausgeprägt, nicht immer unbedingt zur Freude meiner Eltern, denn meine ersten klinischen Erfahrungen sammelte ich bereits in dieser Zeit. In meiner Zivildienstzeit als Rettungssanitäter erlebte ich hautnah mit, wie sehr Verletzungen und Erkrankungen die Lebensqualität der Menschen beeinträchtigen können. 
            Somit folgte ein Studium der Sportwissenschaft mit dem Schwerpunkt Gesundheitssport. Dieses Studium hat mir viel Grundlegendes vermittelt, mich aber auch von den Menschen und ihren konkreten Beschwerden entfernt. Bewegung ist nach meinem Verständnis etwas zutiefst Individuelles, sei es von den Voraussetzungen, den unterschiedlichen Motivationen oder der jeweiligen Vorgeschichte her. Und nur mit einem individuellen Ansatz kann eine Therapie auch erfolgreich sein.
            `
		],
		[
			'',
			`So begann ich 2011 mit dem Studium der Physiotherapie. Dort lernte ich meine Frau kennen, die auch in beruflichen Fragen eine wichtige Ansprechpartnerin ist, sollte ein fachlicher Austausch für eine optimale Behandlung notwendig sein.`
		],
		[
			'What else ...',
			`Das Suchen nach der idealen Lösung bzw. Therapie für ein spezifisches Problem war immer mein Antrieb und ist es bis heute geblieben. Die Arbeit trägt gerade dort besondere Früchte, wo die persönliche Leidenschaft der Antrieb ist.
            Egal, wohin einen das Leben führt, man sollte seine Wege schmerzfrei bestreiten dürfen. Bewegung sollte nicht beschwerlich sein, sondern Freude bereiten und dafür setze ich mich gerne ein. 
            `
		],
	];

	return <Person person={dataChristian} img={personImage} cvs={[ cv, cv2 ]} content={content} />;
}

export const pageQuery = graphql`
	{
		file(relativePath: { eq: "chrisi.jpg" }) {
			...PersonImage
		}
	}
`;

export default Christian;
